import './style.css'
import * as THREE from 'three'
import { Color, CubeReflectionMapping, Frustum, Material, Uniform } from 'three';
import gsap from 'gsap'
import * as dat from 'dat.gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { Sky } from 'three/examples/jsm/objects/Sky.js';



/*
    LoadingBar
*/
var progressBar, startButton, loadingOverlay;

progressBar = document.getElementById('progress-bar');
startButton = document.getElementById('start-button');
loadingOverlay = document.getElementById('loading-overlay');


const loadingManager = new THREE.LoadingManager();/*
    // Loaded
    () => {
        // Wait a little
        
        window.setTimeout(() => {

        }, 3000)
    },

    // Progress
    (itemUrl, itemsLoaded, itemsTotal) => {
        // Calculate the progress and update the loadingBarElement
        const progress = itemsLoaded / itemsTotal * 100;
        //console.log(`Loading progress: ${progress}%`);
        console.log('Loading ' + itemUrl + ': ' + (itemsLoaded / itemsTotal * 100) + '%');
    }
)*/
loadingManager.onStart = function (itemUrl, itemsLoaded, itemsTotal) {
    //console.log('Started loading: ' + itemUrl);

}
loadingManager.onProgress = function (itemUrl, itemsLoaded, itemsTotal) {
    var progress = itemsLoaded / itemsTotal;
    progressBar.style.width = progress * 100 + '%';

}
loadingManager.onLoad = function (itemUrl, itemsLoaded, itemsTotal) {
    //console.log('All items loaded');
    progressBar.style.width = '100%';
    startButton.style.display = 'block';

}

// Handle start button click event
function handleStartButtonClick() {
    //var loadingBar = document.getElementById('loading-bar');
    //loadingBar.style.display = 'none';
    loadingOverlay.style.display = 'none';
    setTimeout(function () {
        //ovdje
        ZoomOut(CorrectZoom(),2);
    }, 20);
}
startButton.addEventListener('click', handleStartButtonClick);

//////Points/////

//////




/*
    Textures
*/

const textureLoader = new THREE.TextureLoader(loadingManager);

const videophone = document.getElementById('videophone');
const vtexture = new THREE.VideoTexture(videophone);
vtexture.flipY = false;
vtexture.needsUpdate = true;
vtexture.minFilter = THREE.LinearFilter;
vtexture.magFilter = THREE.LinearFilter;

const videoMaterial = new THREE.MeshBasicMaterial({
    map: vtexture,
    side: THREE.FrontSide,
    toneMapped: false,
});

const videoscreen = document.getElementById('videoscreen');
const vtexture2 = new THREE.VideoTexture(videoscreen);
vtexture2.flipY = false;
vtexture2.needsUpdate = true;
vtexture2.minFilter = THREE.LinearFilter;
vtexture2.magFilter = THREE.LinearFilter;

const videoMaterial2 = new THREE.MeshBasicMaterial({
    map: vtexture2,
    side: THREE.FrontSide,
    toneMapped: false,
});
/*******VIDEO*********/
const BlackScreenMaterial = new THREE.ShaderMaterial({
    uniforms: {
        uOpacity: { value: 1.0 },
        uCircleRadius: { value: 0 } 
      },
      vertexShader: `
        varying vec2 vUv;
    
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        uniform float uOpacity;
        uniform float uCircleRadius;
        varying vec2 vUv;
    
        void main() {
          // Calculate the distance from the center of the plane
          float dist = length(vUv - 0.5);
    
          // Set the color with transparency for the circle
          vec4 color = vec4(0.0, 0.0, 0.0, uOpacity);
          if (dist < uCircleRadius) {
            color.a = 0.0;
          }
    
          gl_FragColor = color;
        }
      `,
      transparent: true
    });
const BlackScreenMaterial2 = BlackScreenMaterial;
// Function to increase the circle radius from 0 to 1 in a set time
let blackscreenphone=false;
let blackscreen = false;
function increaseCircleRadius(material, duration) {
    const circleRadius = { value: 0.0 }; // Create a wrapper object
    videophone.play();
    // Animate the wrapper object from 0 to 1
    return gsap.to(circleRadius, {
        value: 1.0,
        duration: duration,
        ease: "power1.inOut",
        onUpdate: function () {
            material.uniforms.uCircleRadius.value = circleRadius.value; // Update the uniform value
            material.needsUpdate = true; // Notify Three.js to update the material
        },
        onComplete: () => {
            blackscreenphone = true;// Reset the flag when animation is complete
        }
    });
    
}

// Function to decrease the circle radius from 1 to 0 in a set time
function decreaseCircleRadius(material, duration) {
    const circleRadius = { value: 1.0 }; // Create a wrapper object
    
    // Animate the wrapper object from 1 to 0
    return gsap.to(circleRadius, {
        value: 0.0,
        duration: duration,
        ease: "power1.inOut",
        onUpdate: function () {
            material.uniforms.uCircleRadius.value = circleRadius.value; // Update the uniform value
            material.needsUpdate = true; // Notify Three.js to update the material
        },
        onComplete: () => {
            blackscreenphone=false; // Reset the flag when animation is complete
            videophone.pause();
        }
    });
    
}
function increaseCircleRadiusTV(material, duration) {
    const circleRadius = { value: 0.0 }; // Create a wrapper object
    videoscreen.play();
    // Animate the wrapper object from 0 to 1
    return gsap.to(circleRadius, {
        value: 1.0,
        duration: duration,
        ease: "power1.inOut",
        onUpdate: function () {
            material.uniforms.uCircleRadius.value = circleRadius.value; // Update the uniform value
            material.needsUpdate = true; // Notify Three.js to update the material
        },
        onComplete: () => {
            blackscreen = true; // Reset the flag when animation is complete
        }
    });
    
}

// Function to decrease the circle radius from 1 to 0 in a set time
function decreaseCircleRadiusTV(material, duration) {
    const circleRadius = { value: 1.0 }; // Create a wrapper object

    // Animate the wrapper object from 1 to 0
    return gsap.to(circleRadius, {
        value: 0.0,
        duration: duration,
        ease: "power1.inOut",
        onUpdate: function () {
            material.uniforms.uCircleRadius.value = circleRadius.value; // Update the uniform value
            material.needsUpdate = true; // Notify Three.js to update the material
        },
        onComplete: () => {
            blackscreen = false;
            videoscreen.pause();
        }
    });
    
}

/******/
videophone.onloadeddata = function () {
    videophone.play();
};
videoscreen.onloadeddata = function () {
    videoscreen.play();
};
//



// Cursor

const cursor = {
    x: 0,
    y: 0
}
const mouse = new THREE.Vector2()
//LISTENERS
window.addEventListener('mousemove', (event) => {
    cursor.x = event.clientX / sizes.width - 0.5
    cursor.y = -(event.clientY / sizes.height - 0.5)

    mouse.x = event.clientX / sizes.width * 2 - 1
    mouse.y = - (event.clientY / sizes.height) * 2 + 1

})
let windowWidth;
window.addEventListener('resize', () => {
    //update sizes
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;
    windowWidth = window.innerWidth;

    //update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix()


    //update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})
/*window.addEventListener('dblclick', () => {
    const fullscreenElement = document.fullscreenElement || document.webkitFullScreenElement

    if (!fullscreenElement) {
        if (canvas.requestFullscreen) {
            canvas.requestFullscreen()
        }
        else if (canvas.webkitRequestFullscreen) {
            canvas.webkitRequestFullscreen()
        }
    }
    else {
        if (document.exitFullscreen) {
            document.exitFullscreen()
        }
        else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen()
        }
    }
})*/
// Get a reference to the button element
const button = document.getElementById('start-button');
let canDisable = false;
// Add a click event listener to the button
button.addEventListener('click', function () {
    // Code to execute when the button is clicked
    // Wait for 3 seconds and set the variable to true
    setTimeout(function () {
        canDisable = true;
    }, 100);
});
// Get the drag-to-explore element
const dragToExploreElement = document.querySelector('.drag-to-explore');
//
window.addEventListener("pointerdown", (event) => {
    mouse.x = event.clientX / sizes.width * 2 - 1
    mouse.y = - (event.clientY / sizes.height) * 2 + 1
    if (canDisable) dragToExploreElement.classList.add('disabled');
})


// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 *   Objects
 */

// Load the GLB object
const loader = new GLTFLoader(loadingManager);
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.6/'); // Replace with the actual path to your Draco decoder module
dracoLoader.setDecoderConfig({ type: 'js' });

loader.setDRACOLoader(dracoLoader);

const colorPallete = textureLoader.load('./textures/Pallete.png');
colorPallete.flipY = false;

const colorCupid = textureLoader.load('./textures/CupidTexture.png');
colorCupid.flipY = false;

// Create materials
const palletMaterial = new THREE.MeshPhongMaterial({
    map: colorPallete,
});
const cupidMaterial = new THREE.MeshPhongMaterial({
    map: colorCupid
});
cupidMaterial.skinning = true
//cupidMaterial.morphTargets = true;

let baseMeshMixer = null;
let robotHeadMixer = null;
let telescopeMixer = null;
let KombiMixer = null;
let KombiAction = null;
let Kombi;
let sign;
loader.load('./mesh/BaseScene.glb', function (gltf) {
    const baseScene = gltf.scene;

    // Get the objects from the BaseScene
    const baseMesh = baseScene.getObjectByName('BaseMesh');
    baseMesh.receiveShadow = true;
    baseMesh.castShadow = true;
    baseMesh.material = cupidMaterial;

    const cupidMesh = baseMesh.getObjectByName('Cupid');
    cupidMesh.receiveShadow = true;
    cupidMesh.castShadow = true;
    cupidMesh.material = cupidMaterial;

    const robotHead = baseScene.getObjectByName('robot_head');
    robotHead.receiveShadow = true;
    robotHead.castShadow = true;
    robotHead.material = palletMaterial;

    const telescope = baseScene.getObjectByName('Telescope');
    telescope.receiveShadow = true;
    telescope.castShadow = true;
    telescope.material = palletMaterial;

    const staticObject = baseScene.getObjectByName('static');
    staticObject.receiveShadow = true;
    staticObject.castShadow = true;
    staticObject.material = palletMaterial;

    const phoneScreen = baseScene.getObjectByName('Phone_Screen');
    phoneScreen.material = videoMaterial;
    phoneScreen.needsUpdate = true;//true;

    const phoneBlackScreen = baseScene.getObjectByName('Phone_BlackScreen');
    phoneBlackScreen.material = BlackScreenMaterial;
    

    const screen = baseScene.getObjectByName('Screen');
    screen.material = videoMaterial2;
    screen.needsUpdate = true;

    const Bscreen = baseScene.getObjectByName('BlackScreen');
    Bscreen.material = BlackScreenMaterial2;
    

    sign = baseScene.getObjectByName('Sign');
    sign.material = palletMaterial;
    sign.receiveShadow = true;
    sign.castShadow = true;

    Kombi = baseScene.getObjectByName('Kombi');
    Kombi.material = palletMaterial;
    Kombi.receiveShadow = true;
    Kombi.castShadow = true;

    //staticObject.matrixAutoUpdate = false;

    /*baseScene.traverse(function (node) {
        if (node.isMesh && node !== baseMesh && node !== cupidMesh) {
            node.material = palletMaterial;
        }
    });*/

    // Add the objects to the scene
    scene.add(baseScene);

    // Create an animation mixer for the BaseMesh (Cupid mesh)
    baseMeshMixer = new THREE.AnimationMixer(baseMesh);

    // Play the "idle" animation for the BaseMesh
    const baseMeshAnimation = gltf.animations.find(animation => animation.name === 'idle');
    const baseMeshAction = baseMeshMixer.clipAction(baseMeshAnimation);
    baseMeshAction.play();

    // Create an animation mixer for the robot_head mesh
    robotHeadMixer = new THREE.AnimationMixer(robotHead);

    // Play the "Robot_Anim" animation for the robot_head mesh
    const robotHeadAnimation = gltf.animations.find(animation => animation.name === 'Robot_Anim');
    const robotHeadAction = robotHeadMixer.clipAction(robotHeadAnimation);
    robotHeadAction.play();

    // Create an animation mixer for the Telescope mesh
    telescopeMixer = new THREE.AnimationMixer(telescope);

    // Play the "Telescope_Anim" animation for the Telescope mesh
    const telescopeAnimation = gltf.animations.find(animation => animation.name === 'Telescope_Anim');
    const telescopeAction = telescopeMixer.clipAction(telescopeAnimation);
    telescopeAction.play();

    KombiMixer = new THREE.AnimationMixer(Kombi);
    const KombiAnimation = gltf.animations.find(animation => animation.name === 'Kombi');

    KombiAction = KombiMixer.clipAction(KombiAnimation);
    KombiAction.setLoop(THREE.LoopOnce);

    // Create an animation mixer
    //mixer = new THREE.AnimationMixer(model);

    // Get the animation
    //const animation = gltf.animations[0]; // Assuming the animation is at index 0
    // Play the animation
    //const action = mixer.clipAction(animation);
    //action.play();


});
///REVEAAL SCREEN///
let revealStarted = false;
function animateReveal() {
    const startTime = performance.now();
    const duration = 2.0; // Adjust the duration as needed

    function updateShaderMaterial() {
        const elapsed = (performance.now() - startTime) / 1000; // Convert to seconds
        const alpha = Math.min(elapsed / duration, 1.0);
        shaderMaterial.uniforms.time.value = alpha;

        if (alpha < 1.0) {
            requestAnimationFrame(updateShaderMaterial);
        }
    }

    updateShaderMaterial();
}

///Kombi////

function playAnimation() {

    if(KombiAction)KombiAction.reset(); // Reset the animation to the beginning
    if(KombiAction)KombiAction.play(); // Play the animation

}

///////SIGN////////
let isAnimating = false;

function spinObject(object, duration) {
    if (isAnimating) {
        return; // Exit the function if animation is already in progress
    }

    isAnimating = true; // Set the flag to indicate animation is in progress

    const rotation = Math.random() * (360 - 180) + 180;

    gsap.to(object.rotation, {
        y: `+=${rotation}`,
        duration: duration,
        ease: 'power1.easeOut',
        onComplete: () => {
            isAnimating = false; // Reset the flag when animation is complete
        },
    });
}

// Add ambient light
const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
scene.add(ambientLight);

// Add hemisphere light
const hemisphereLight = new THREE.HemisphereLight(0xC3D9FF, 0xEDEDED, 0.5);
scene.add(hemisphereLight);

// Add directional light
const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
directionalLight.position.set(10, 10, -12);
directionalLight.castShadow = true;

// Set up shadow properties
directionalLight.shadow.mapSize.width = 512;
directionalLight.shadow.mapSize.height = 512;
directionalLight.shadow.camera.near = 0.1;
directionalLight.shadow.camera.far = 500;
directionalLight.shadow.camera.left = -10;
directionalLight.shadow.camera.right = 10;
directionalLight.shadow.camera.top = 10;
directionalLight.shadow.camera.bottom = -10;


scene.add(directionalLight);




const params = {
    color: '#ffffff',
    aovalue: 0.8
};

scene.background = new THREE.Color(params.color)

// AXES HELPER
const axesHelper = new THREE.AxesHelper(3);
//scene.add(axesHelper);
// Debug objects

/*
    LIGHTS
*/


/**
 * Raycaster
 */
//Triggers
const boxGeometry = new THREE.BoxGeometry(3, 3.5, 1.5);

const triggerVan = new THREE.Mesh(boxGeometry);
triggerVan.visible = false;

triggerVan.rotation.set(0, 45, 0)
triggerVan.position.set(5.5, 1, 5.69);

scene.add(triggerVan);
//
const triggerQuat = new THREE.Mesh(boxGeometry);
triggerQuat.visible = false;

triggerQuat.rotation.set(0, 90, 0)
triggerQuat.position.set(7, 1, -2.8);

scene.add(triggerQuat);
//
const triggerPhone = new THREE.Mesh(boxGeometry);
triggerPhone.visible = false;

triggerPhone.rotation.set(0, -10, 0);
triggerPhone.position.set(2, 1, -7.7);

scene.add(triggerPhone);
//
const triggerScreen = new THREE.Mesh(boxGeometry);
triggerScreen.visible = false;

triggerScreen.rotation.set(0, 20, 0)
triggerScreen.position.set(-6.6, 1, -1.57);

scene.add(triggerScreen);
//
const triggerSign = new THREE.Mesh(boxGeometry);
triggerSign.visible = false;

triggerSign.rotation.set(0, 0, 0)
triggerSign.position.set(-3.1, 1, 7.7);

scene.add(triggerSign);
const triggerBoids = new THREE.Mesh(boxGeometry);
triggerBoids.visible = false;

triggerBoids.rotation.set(0, 10, 0)
triggerBoids.position.set(-3.8, 1, -6.6);

scene.add(triggerBoids);





/**
 * Sizes
**/
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

/**
 * Camera
**/

//PERSPECTIVE
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.01, 250)

camera.position.set(0, 0, 17);

scene.add(camera)
//PERSPECTIVE
//camera.position.set(2.5,1,2.5);

// Controls
const controls = new OrbitControls(camera, canvas)

controls.target.set(0, 0, 0);
// Set the initial position and rotation
var radius = 13; // Set the desired radius
var theta = THREE.MathUtils.degToRad(75); // Set the desired rotation angle in degrees and convert to radians

// Calculate the initial position
var x = radius * Math.cos(theta);
var z = radius * Math.sin(theta);

camera.position.set(x, 0, z);

controls.enableDamping = true;
//controls.target = 
controls.enablePan = false;
controls.enableZoom = true; // ORTOGRAPHIC
controls.rotateSpeed = 0.4;

//controls.maxAzimuthAngle = Math.PI * 0.5;
//controls.minAzimuthAngle = 0; 
controls.maxPolarAngle = THREE.MathUtils.degToRad(85); //Math.PI * 0.5;
controls.minPolarAngle = THREE.MathUtils.degToRad(30); //0; 
var MinimalDistance = 10;
controls.minDistance = MinimalDistance;
controls.maxDistance = CorrectMaxDistance();//17.5;

function ZoomOut(desiredValue, duration) {
    // Store the initial value of controls.minDistance
    const initialValue = controls.object.position.distanceTo(controls.target);
    controls.minDistance = initialValue;
    // Use GSAP to create the animation
    gsap.to(controls, {
      minDistance: desiredValue,
      duration: duration,
      ease: "power1.out",
      onUpdate: function () {

      },
      onComplete: function () {
        // Animation complete, set minDistance back to initial value
        controls.minDistance = MinimalDistance;
      }
    });
}



const renderer = new THREE.WebGLRenderer({
    canvas: canvas

})

renderer.shadowMap.enabled = true; // Enable shadow mapping
renderer.shadowMap.type = THREE.PCFSoftShadowMap;

renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.setSize(sizes.width, sizes.height)

//renderer.toneMapping = THREE.ACESFilmicToneMapping;
//renderer.toneMappingExposure = 0.7;

///////
// Calculate the center of the scene
const sceneCenter = new THREE.Vector3(0, 0, 0);
// Perform the raycasting only against the trigger objects
const triggerObjects = [triggerSign, triggerVan, triggerQuat, triggerPhone, triggerScreen, triggerBoids];
const raycaster = new THREE.Raycaster();
// Limit the ray length
raycaster.far = 12;
// Hide all HTML elements initially
var signElement = document.getElementById('triggerSign_element')//.style.display = 'none';
var vanElement = document.getElementById('triggerVan_element')//.style.display = 'none';
var quatElement = document.getElementById('triggerQuat_element')//.style.display = 'none';
var phoneElement = document.getElementById('triggerPhone_element')//.style.display = 'none';
var screenElement = document.getElementById('triggerScreen_element')//.style.display = 'none';
var boidsElement = document.getElementById('triggerBoids_element')

let isInTrigger = false;
function raycast() {

    // Set the raycaster origin to the camera position
    // Set the raycaster's origin to the camera's position
    raycaster.setFromCamera(new THREE.Vector2(0, 0), camera);
    //raycaster.set(camera.position, sceneCenter.clone().sub(camera.position).normalize());

    // Perform the raycasting only against the trigger objects
    const intersects = raycaster.intersectObjects(triggerObjects, true);


    // Hide all HTML elements initially
    //var signElement = document.getElementById('triggerSign_element')//.style.display = 'none';
    //var vanElement = document.getElementById('triggerVan_element')//.style.display = 'none';
    //var quatElement = document.getElementById('triggerQuat_element')//.style.display = 'none';
    //var phoneElement = document.getElementById('triggerPhone_element')//.style.display = 'none';
    //var screenElement = document.getElementById('triggerScreen_element')//.style.display = 'none';
    //hide the elements
    signElement.classList.add('hidden');
    signElement.classList.remove('visible');
    vanElement.classList.add('hidden');
    vanElement.classList.remove('visible');
    quatElement.classList.add('hidden');
    quatElement.classList.remove('visible');
    phoneElement.classList.add('hidden');
    phoneElement.classList.remove('visible');
    screenElement.classList.add('hidden');
    screenElement.classList.remove('visible');
    boidsElement.classList.add('hidden');
    boidsElement.classList.remove('visible');

    if (intersects.length > 0) {
        const triggerHit = intersects[0].object;
        //console.log("HIT");
        // Show the corresponding content based on the trigger name
        showContent(triggerHit);
        isInTrigger = true;
    } else {
        isInTrigger = false;
        if(blackscreenphone)decreaseCircleRadius(BlackScreenMaterial,0.5);
        if(blackscreen)decreaseCircleRadiusTV(BlackScreenMaterial2,0.5);
    }
}
function showContent(triggerHit) {
    // Hide all content elements


    if (triggerHit === triggerSign) {
        //document.getElementById('triggerSign_element').style.display = 'block';
        signElement.classList.add('visible');
        signElement.classList.remove('hidden');
        if (!isInTrigger) spinObject(sign, 1.5);
    } else if (triggerHit === triggerVan) {
        //document.getElementById('triggerVan_element').style.display = 'block';
        vanElement.classList.add('visible');
        vanElement.classList.remove('hidden');
        if (!isInTrigger) playAnimation();
    } else if (triggerHit === triggerQuat) {
        //document.getElementById('triggerQuat_element').style.display = 'block';
        quatElement.classList.add('visible');
        quatElement.classList.remove('hidden');
    } else if (triggerHit === triggerPhone) {
        //document.getElementById('triggerPhone_element').style.display = 'block';
        phoneElement.classList.add('visible');
        phoneElement.classList.remove('hidden');
        if(!isInTrigger) increaseCircleRadius(BlackScreenMaterial,0.5);
    } else if (triggerHit === triggerScreen) {
        //document.getElementById('triggerScreen_element').style.display = 'block';
        screenElement.classList.add('visible');
        screenElement.classList.remove('hidden');
        if(!isInTrigger)increaseCircleRadiusTV(BlackScreenMaterial2,0.5);
    } else if (triggerHit == triggerBoids) {
        boidsElement.classList.add('visible');
        boidsElement.classList.remove('hidden');
        
    }

}

///////
// Create the boids
const numBoids = 12; // Number of boids
let numberOfBoids = numBoids;
const boids = [];

// Set up the box
const boxSize = new THREE.Vector3(11.28, 3, 17); // Custom box size (8,3,8)
const boxPosition = new THREE.Vector3(-1.995, 4.71, 0); // Custom box position (-0.56,4.65,0)

const boxHalfSize = new THREE.Vector3(boxSize.x / 2, boxSize.y / 2, boxSize.z / 2);


let birdMeshVar; // Variable to store the loaded bird mesh

loader.load('./mesh/Bird.glb', function (gltf) {
    const baseScene = gltf.scene;
    var birdMesh = baseScene.getObjectByName('Bird');
    birdMesh.material = palletMaterial;
    birdMeshVar = birdMesh;
    for (var i = 0; i < numBoids; i++) {
        var bird = birdMesh.clone(); // Create an instance of the loaded mesh
        bird.position.set(
            Math.random() * boxSize.x - boxHalfSize.x + boxPosition.x,
            Math.random() * boxSize.y - boxHalfSize.y + boxPosition.y,
            Math.random() * boxSize.z - boxHalfSize.z + boxPosition.z
        );
        bird.velocity = new THREE.Vector3(
            Math.random(),
            Math.random(),
            Math.random());

        //bird.rotation.z = Math.atan2(-bird.velocity.x, bird.velocity.y);
        boids.push(bird);

        scene.add(bird);
    }
})

// Update the boid count
const boidCountHtml = document.getElementById('boidCount');

boidCountHtml.textContent = numberOfBoids.toString();
// Function to add boids
function addBoids() {

    if (numberOfBoids > 999) return;

    if (!birdMeshVar) {
        //console.log('Bird model not loaded yet.');
        return;
    }
    const bird = birdMeshVar.clone(); // Create an instance of the loaded mesh
    bird.position.set(
        Math.random() * boxSize.x - boxHalfSize.x + boxPosition.x,
        Math.random() * boxSize.y - boxHalfSize.y + boxPosition.y,
        Math.random() * boxSize.z - boxHalfSize.z + boxPosition.z
    );
    bird.velocity = new THREE.Vector3(
        Math.random(),
        Math.random(),
        Math.random());
    boids.push(bird);
    scene.add(bird);
    numberOfBoids += 1;
    boidCountHtml.textContent = numberOfBoids.toString();
}
// Function to remove boids
function removeBoids() {
    if (numberOfBoids > 0) {
        const bird = boids.pop();
        scene.remove(bird);
        numberOfBoids -= 1;
        boidCountHtml.textContent = numberOfBoids.toString();
    }
}
// Add event listeners to the buttons
const addButton = document.getElementById('addButton');
addButton.addEventListener('click', addBoids);

const removeButton = document.getElementById('removeButton');
removeButton.addEventListener('click', removeBoids);
/*for (let i = 0; i < numBoids; i++) {
    const boidGeometry = new THREE.BoxGeometry(0.1, 0.1, 0.1); // Boid size
    const boidMaterial = new THREE.MeshBasicMaterial({ color: 0x00ff00 }); // Boid color
    const boid = new THREE.Mesh(boidGeometry, boidMaterial);
    boid.position.set(
        Math.random() * boxSize.x - boxSize.x / 2,
        Math.random() * boxSize.y - boxSize.y / 2,
        Math.random() * boxSize.z - boxSize.z / 2
    );
    boid.velocity = new THREE.Vector3(Math.random(), Math.random(), Math.random());
    boids.push(boid);
    scene.add(boid);
}*/


// Function to handle boid movement and behavior
function updateBoids() {

    // Move the boids
    for (const boid of boids) {
        const cohesionRadius = 3; // Radius for cohesion behavior
        const separationRadius = 1; // Radius for separation behavior
        const alignmentRadius = 3; // Radius for alignment behavior
        const maxSpeed = 0.05; // Maximum speed of the boids
        const minSpeed = 0.025;
        // Calculate the forces
        const cohesion = new THREE.Vector3();
        const separation = new THREE.Vector3();
        const alignment = new THREE.Vector3();
        let neighborsCount = 0;

        for (const otherBoid of boids) {
            if (otherBoid !== boid) {
                const distance = boid.position.distanceTo(otherBoid.position);

                if (distance < cohesionRadius) {
                    cohesion.add(otherBoid.position);
                    neighborsCount++;
                }

                if (distance < separationRadius) {
                    const diff = new THREE.Vector3().subVectors(boid.position, otherBoid.position);
                    diff.divideScalar(distance * distance);
                    separation.add(diff);
                }

                if (distance < alignmentRadius) {
                    alignment.add(otherBoid.velocity);
                }
            }
        }

        if (neighborsCount > 0) {
            cohesion.divideScalar(neighborsCount);
            cohesion.sub(boid.position);
            cohesion.normalize();
        }

        separation.normalize();
        alignment.normalize();

        // Apply the forces to the boid
        const cohesionForce = cohesion.multiplyScalar(0.05).multiplyScalar(Math.random()); //cohesion.clone().multiplyScalar(0.05);
        const separationForce = separation.multiplyScalar(0.3).multiplyScalar(Math.random());
        const alignmentForce = alignment.multiplyScalar(0.1).multiplyScalar(Math.random());

        const steering = new THREE.Vector3();
        steering.add(cohesionForce);
        steering.add(separationForce);
        steering.add(alignmentForce);

        // Apply steering behavior
        const maxSteeringForce = 0.02;
        steering.clampLength(0, maxSteeringForce);
        boid.velocity.add(steering);

        // Limit the boid's speed
        if (boid.velocity.length() > maxSpeed) {
            boid.velocity.normalize().multiplyScalar(maxSpeed);
        }
        // Limit the boid's speed
        if (boid.velocity.length() < minSpeed) {
            boid.velocity.normalize().multiplyScalar(minSpeed);
        }
        // Update the boid's position
        boid.position.add(boid.velocity);

        // Turn back when reaching the box boundaries
        const margin = 1;
        const randomizationFactor = 0.5;

        if (boid.position.x < boxPosition.x - boxSize.x / 2 + margin) {
            boid.velocity.x = Math.abs(boid.velocity.x) * Math.random() * randomizationFactor;
        } else if (boid.position.x > boxPosition.x + boxSize.x / 2 - margin) {
            boid.velocity.x = -Math.abs(boid.velocity.x) * Math.random() * randomizationFactor;
        }

        if (boid.position.y < boxPosition.y - boxSize.y / 2 + margin) {
            boid.velocity.y = Math.abs(boid.velocity.y) * Math.random() * randomizationFactor;
        } else if (boid.position.y > boxPosition.y + boxSize.y / 2 - margin) {
            boid.velocity.y = -Math.abs(boid.velocity.y) * Math.random() * randomizationFactor;
        }

        if (boid.position.z < boxPosition.z - boxSize.z / 2 + margin) {
            boid.velocity.z = Math.abs(boid.velocity.z) * Math.random() * randomizationFactor;
        } else if (boid.position.z > boxPosition.z + boxSize.z / 2 - margin) {
            boid.velocity.z = -Math.abs(boid.velocity.z) * Math.random() * randomizationFactor;
        }


        // Calculate the rotation quaternion based on the velocity direction
        const targetRotation = new THREE.Quaternion().setFromUnitVectors(
            new THREE.Vector3(1, 0, 0), // Initial orientation of the boid
            boid.velocity.clone().normalize()
        );

        // Apply quaternion slerp to gradually rotate the boid
        const rotationSpeed = 0.1;
        boid.quaternion.rotateTowards(targetRotation, rotationSpeed);
    }

}
/////////SKY/////////
let sky, sun;
// Add Sky
sky = new Sky();
sky.scale.setScalar(450000);
scene.add(sky);

sun = new THREE.Vector3();

/// GUI

const effectController = {
    turbidity: 13.4,
    rayleigh: 0.122,
    mieCoefficient: 0.001,
    mieDirectionalG: 0.182,
    elevation: 12.5,
    azimuth: 137,
    exposure: renderer.toneMappingExposure
};

function guiChanged() {

    const uniforms = sky.material.uniforms;
    uniforms['turbidity'].value = effectController.turbidity;
    uniforms['rayleigh'].value = effectController.rayleigh;
    uniforms['mieCoefficient'].value = effectController.mieCoefficient;
    uniforms['mieDirectionalG'].value = effectController.mieDirectionalG;

    const phi = THREE.MathUtils.degToRad(90 - effectController.elevation);
    const theta = THREE.MathUtils.degToRad(effectController.azimuth);

    sun.setFromSphericalCoords(1, phi, theta);

    uniforms['sunPosition'].value.copy(sun);

    renderer.toneMappingExposure = effectController.exposure;
    renderer.render(scene, camera);

}

/*const gui =  new dat.GUI();

gui.add(effectController, 'turbidity', 0.0, 20.0, 0.1).onChange(guiChanged);
gui.add(effectController, 'rayleigh', 0.0, 4, 0.001).onChange(guiChanged);
gui.add(effectController, 'mieCoefficient', 0.0, 0.1, 0.001).onChange(guiChanged);
gui.add(effectController, 'mieDirectionalG', 0.0, 1, 0.001).onChange(guiChanged);
gui.add(effectController, 'elevation', 0, 90, 0.1).onChange(guiChanged);
gui.add(effectController, 'azimuth', - 180, 180, 0.1).onChange(guiChanged);
gui.add(effectController, 'exposure', 0, 1, 0.0001).onChange(guiChanged);
*/
guiChanged();
/*******MOBILE FRIENDLY -.-*******/
// Function to handle window resize

function CorrectMaxDistance()
{
    if (windowWidth < 768) {
        return 22 
        } else {
        return 17.5
        }
}
function CorrectZoom() 
{
    if (windowWidth < 768) {
      return 19 
    } else {
      return 16.5
    }
}
// Time
//let time = Date.now();
var clock = new THREE.Clock();

const tick = () => {


    updateBoids();
    var delta = clock.getDelta();


    //if ( mixer ) mixer.update( delta );
    // Update the animation mixers
    if (baseMeshMixer) baseMeshMixer.update(delta);
    if (robotHeadMixer) robotHeadMixer.update(delta);
    if (telescopeMixer) telescopeMixer.update(delta);
    if (KombiMixer) KombiMixer.update(delta);
    // update camera
    controls.update();

    raycast()

    //renderer
    renderer.render(scene, camera)

    window.requestAnimationFrame(tick)
}

tick();